import api from "../axios_service.js";

export async function fetchOrdensServico(filtros) {
  const response = await api.get(`/ordens-servicos${filtros ? filtros : ""}`);
  return response.data.result;
}

export async function fetchOrdensServicoFinalizados() {
  const response = await api.get(`/ordens-servicos-finalizados`);
  return response.data.result;
}

export async function fetchOrdemServico(id) {
  const response = await api.get(`/ordens-servicos/${id}`);
  return response.data.result;
}

export async function fetchOsFornecedoresServicos(id) {
  const response = await api.get(
    `/ordens-servicos-fornecedores-servicos/${id}`
  );
  return response.data.result;
}

export async function osProntaInstalacao(id, body) {
  return api.post(`/ordens-servicos-pronta-instalacao/${id}`, body);
}
export async function osEnviadoInstalacao(id, body) {
  return api.post(`/ordens-servicos-enviado-instalacao/${id}`, body);
}

export async function osDataInstalacao(id, body) {
  return api.post(`/ordens-servicos-data-instalacao/${id}`, body);
}

export async function osDataConclusao(id, body) {
  return api.post(`/ordens-servicos-data-conclusao/${id}`, body);
}

export async function osKanbanStatus(id, body) {
  return api.post(`/ordens-servicos-kanban-status/${id}`, body);
}

export function iniciaBaixaOs(id, body) {
  return api.post(`/inicia-baixa-os/${id}`, body);
}

export function faturaOs(body) {
  return api.post(`/faturar-os`, body);
}

export function fechaBaixaOs(id) {
  return api.post(`/fecha-baixa-os/${id}`);
}


export async function fetchTicketOs(id) {
  const response = await api.get(`/os-ticket/${id}`);
  return response.data.result;
}

export function infoPagareOsFinalizada(id, body) {
  return api.post(`/info-pagare-os-finalizada/${id}`, body);
}

export async function salvarOsManual(body, id) {
  if (id === undefined || id === null) {
    return api.post(`/salvar-os-manual`, body);
  }
  return api.post(`/salvar-os-manual/${id}`, body);
}

export async function cancelarOrdemServicoAntesDaBaixa(id) {
  return api.put(`/cancelar-os/${id}`);
}

export async function cancelarOrdemServicoFaturadaCaixaAfItem(id) {
  return api.put(`/cancelar-os-faturada-caixa-af-item/${id}`);
}

export async function cancelarOrdemServicoFaturada(id) {
  return api.put(`/cancelar-os-faturada/${id}`);
}

export async function enviarCaixaOS(body, id) {
  return api.post(`/enviar-caixa-os/${id}`, body);
}