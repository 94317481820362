<template>
  <v-container fluid>
    <PageToolbar
      :title="$tc('global.ordensServicos', 2)"
      icon="mdi-toolbox-outline"
      dark
    />
    <v-card>
      <v-card-title class="d-flex justify-space-between tocs_gray_1">
        <div class="d-flex align-center justify-space-between">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$tc('global.buscar')"
            :style="{ width: $vuetify.breakpoint.mdAndUp ? '502px' : '' }"
            :placeholder="painelKanban ? 'Buscar por nombre cliente' : ''"
            height="my-5"
            clearable
            outlined
            solo
            flat
            dense
            hide-details
          >
          </v-text-field>
          <v-btn
            min-width="40px"
            min-height="40px"
            class="ml-3"
            style="
              border-style: solid;
              border-width: 1px;
              padding: 0px 0px 0px 0px;
            "
            @click="openClosePainelFilter"
          >
            <v-icon>mdi-filter-outline</v-icon>
          </v-btn>
        </div>

        <div
          v-if="$vuetify.breakpoint.mdAndUp"
          class="d-flex align-center justify-space-between ml-3"
        >
          <span class="text-subtitle-1 mr-3">Ver quadro</span>
          <v-switch
            :true-value="true"
            :false-value="false"
            v-model="painelKanban"
          ></v-switch>
        </div>

        <v-spacer class="d-none d-sm-flex"></v-spacer>

        <v-btn
          v-if="POrdensServicosAdicionar"
          color="button_1"
          :block="!this.$vuetify.breakpoint.mdAndUp ? true : false"
          class="white--text mt-4 mt-sm-2"
          @click="addOrdemServicoManual"
        >
          <v-icon left>mdi-plus</v-icon>
          OS Avulsa
        </v-btn>
      </v-card-title>
      <v-card v-if="painelFilter" flat solo class="px-4 pb-4 tocs_gray_1">
        <v-card class="pa-4" flat>
          <v-row class="">
            <v-col cols="12" md="2">
              <DataField
                label="Inicio"
                :solo="true"
                :flat="true"
                :data_sync.sync="dataInicio"
              />
            </v-col>
            <v-col cols="12" md="2">
              <DataField
                label="Fim"
                :solo="true"
                :flat="true"
                :data_sync.sync="dataFim"
              />
            </v-col>
            <v-col cols="12" md="">
              <v-select
                v-model="statusFilter"
                :items="status_lista"
                label="Status"
                multiple
                hide-details
                flat
                dense
                outlined
                solo
              >
                <template v-slot:item="{ item }">
                  <div class="d-flex align-center justify-start">
                    <v-sheet
                      width="10px"
                      height="10px"
                      class="mr-2"
                      :color="item.color"
                    ></v-sheet>
                    {{ item.text }}
                  </div>
                </template>
                <template v-slot:selection="{ item, index }">
                  <div class="d-flex align-center justify-start mt-sm-1">
                    <v-sheet
                      width="10px"
                      height="10px"
                      :color="item.color"
                      class="mx-2"
                    >
                    </v-sheet>
                    <span class="text-caption">{{ item.text }}</span>
                    <span v-if="index < statusFilter.length - 1"></span>
                  </div>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" md="2">
              <ServicosOrdemServico
                :selectedFornecedor.sync="selectedFornecedor"
                @atualizarFornecedor="atualizarFornecedor($event)"
              />
            </v-col>
            <v-col cols="12" md="12" class="d-flex align-right px-0 py-0">
              <v-spacer></v-spacer>
              <v-btn color="" text @click="closePainelFilter"> Cancelar </v-btn>
              <v-btn color="#232D58" text @click="getOrdemServicos">
                Filtrar
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-card>
      <v-divider></v-divider>

      <v-card-text>
        <v-data-table
          v-if="!painelKanban && !loading"
          :loading="loading"
          :headers="headers"
          :search="search"
          :items="ordem_servicos"
          :items-per-page="10"
          sort-by="id"
          :sort-desc="true"
          class="data-tables"
          :class="{ 'data-tables__row-click': POrdensServicosVisualizar }"
          @click:row="goToOrdemServico"
        >
          <template v-slot:item.previsao_inicio="{ item }">
            {{ item.previsao_inicio | dateFormat("dd/MM/yyyy") }}
          </template>
          <template v-slot:item.previsao_fim="{ item }">
            {{ item.previsao_fim | dateFormat("dd/MM/yyyy") }}
          </template>
          <template v-slot:item.valor="{ item }">
            {{ item.moeda_sigla }}
            <span v-if="item.moeda_sigla === 'G$'">
              {{ item.valor | guarani }}
            </span>
            <span v-else>
              {{ item.valor | currency }}
            </span>
          </template>
          <template v-slot:item.desconto="{ item }">
            {{ item.moeda_sigla }}
            <span v-if="item.moeda_sigla === 'G$'">
              {{ item.desconto | guarani }}
            </span>
            <span v-else>
              {{ item.desconto | currency }}
            </span>
          </template>
          <template v-slot:item.total="{ item }">
            {{ item.moeda_sigla }}
            <span v-if="item.moeda_sigla === 'G$'">
              {{ (item.valor - item.desconto) | guarani }}
            </span>
            <span v-else>
              {{ (item.valor - item.desconto) | currency }}
            </span>
          </template>
          <template v-slot:item.status="{ item }">
            <div class="d-flex align-center" v-if="item.status === -1">
              <v-sheet
                width="10px"
                height="10px"
                color="red darken-1"
                style="border-radius: 3px"
                class="mr-2"
              >
              </v-sheet>
              {{ $tc("global.cancelado") }}
            </div>

            <div class="d-flex align-center" v-if="item.status === 1">
              <v-sheet
                width="10px"
                height="10px"
                color="yellow lighten-1"
                style="border-radius: 3px"
                class="mr-2"
              >
              </v-sheet>
              {{ $tc("global.pendentes") }}
            </div>

            <div class="d-flex align-center" v-if="item.status === 2">
              <v-sheet
                width="10px"
                height="10px"
                color="lime darken-2"
                style="border-radius: 3px"
                class="mr-2"
              >
              </v-sheet>
              {{ $tc("global.emPreparação") }}
            </div>
            <div class="d-flex align-center" v-if="item.status === 3">
              <v-sheet
                width="10px"
                height="10px"
                color="light-blue darken-3"
                style="border-radius: 3px"
                class="mr-2"
              >
              </v-sheet>
              {{ $tc("global.aguardando") + " " + $tc("global.instalacao") }}
            </div>
            <div class="d-flex align-center" v-if="item.status === 4">
              <v-sheet
                width="10px"
                height="10px"
                color="orange lighten-1"
                style="border-radius: 3px"
                class="mr-2"
              >
              </v-sheet>
              {{ "Em " + $tc("global.instalacao") }}
            </div>
            <div
              class="d-flex align-center"
              v-if="
                item.status === 5 &&
                (item.enviado_caixa == null || item.data_pago_caixa)
              "
            >
              <v-sheet
                width="10px"
                height="10px"
                color="green lighten-1"
                style="border-radius: 3px"
                class="mr-2"
              >
              </v-sheet>
              {{ $tc("global.finalizado") }}
            </div>
            <div
              class="d-flex align-center"
              v-if="
                item.status === 5 &&
                item.enviado_caixa != null &&
                !item.data_pago_caixa
              "
            >
              <v-sheet
                width="10px"
                height="10px"
                color="green lighten-1"
                style="border-radius: 3px"
                class="mr-2"
              >
              </v-sheet>
              {{ $tc("global.enviadoCaixa") }}
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="item.status !== 0 || item.status !== 1"
                  icon
                  @click.stop="gerarpdf(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-basket</v-icon>
                </v-btn>
              </template>
              <span>{{ $tc("global.produto", 2) }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="item.status >= 3"
                  icon
                  @click.stop="gerarCheckList(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-check-underline-circle-outline</v-icon>
                </v-btn>
              </template>
              <span>Check list</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="item.status >= 3"
                  icon
                  @click.stop="gerarTermo(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-file-export</v-icon>
                </v-btn>
              </template>
              <span>{{ $tc("global.termo") }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="podeDeletar(item)"
                  icon
                  @click.stop="openCancelamentoOs(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>{{ $tc("global.cancelar") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <KanbanOrdemServico
          v-else-if="!loading"
          :ordem_servicos.sync="ordem_servicos"
          :search.sync="search"
          @fetch-ordens-servicos="getOrdemServicos"
          @select-ordem-servico="goToOrdemServico"
        />

        <Carregando v-else />
      </v-card-text>
    </v-card>

    <DialogOrdemServico
      v-if="dialogOrdemServico"
      :dialogOrdemServico.sync="dialogOrdemServico"
      :editar="editar"
      :ordem_servico_id="ordem_servico_id"
      @fetch-ordens-servicos="getOrdemServicos"
    />
    <DialogOrdemServicoManual
      v-if="dialogOrdemServicoManual"
      :dialogOrdemServicoManual.sync="dialogOrdemServicoManual"
      :editar="editar"
      :ordem_servico_id="ordem_servico_id"
      @fetch-ordens-servicos="getOrdemServicos"
    />

    <PdfDialogComp
      v-if="pdfDialog"
      :pdfDialog.sync="pdfDialog"
      :pdfData="pdfData"
      :title="$tc('global.ordensServicos')"
    />

    <DialogCancelarOs
      v-if="dialogCancelarOs"
      :dialogCancelarOs.sync="dialogCancelarOs"
      :ordemServico="dadosParaCancelar"
      @cancelar-os="getOrdemServicos"
    />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { fetchOrdensServico } from "@/api/ordem-servico/ordem_servicos.js";
import { format } from "date-fns";
const pdfGenerator = () =>
  import(/* webpackChunkName: "produtos-pdf" */ "./reports/ordem_servico.js");

const listaOsManual = () =>
  import(
    /* webpackChunkName: "produtos-pdf" */ "./reports/ordem_servico_manual.js"
  );

const pdfChecklist = () =>
  import(/* webpackChunkName: "check-list-pdf" */ "./reports/check_list.js");

const pdfTermo = () =>
  import(/* webpackChunkName: "check-list-pdf" */ "./reports/termo_piscina.js");

const pdfTermoManual = () =>
  import(/* webpackChunkName: "check-list-pdf" */ "./reports/termo_manual.js");

export default {
  name: "OrdensServicos",

  components: {
    DialogOrdemServico: () => import("./components/DialogOrdemServico.vue"),
    PdfDialogComp: () => import("@/components/global/PdfDialogComp.vue"),
    DialogOrdemServicoManual: () =>
      import("./components/DialogOrdemServicoManual.vue"),
    DataField: () => import("@/components/fields/DataField.vue"),
    KanbanOrdemServico: () => import("./components/KanbanOrdemServico.vue"),
    ServicosOrdemServico: () => import("./components/ServicosOrdemServico.vue"),
    DialogCancelarOs: () => import("./components/DialogCancelarOs.vue"),
  },

  data() {
    return {
      search: "",
      loading: false,
      ordem_servicos: [],
      ordem_servico_id: null,
      editar: false,
      dialogOrdemServico: false,
      statusFilter: [1, 2, 3, 4, 5],
      prestadoresFilter: [],
      prestadores_lista: [],
      selectedFornecedor: null,
      dataInicio: null,
      dataFim: null,
      pdfDialog: false,
      pdfData: "",
      loadingPdf: false,
      dialogOrdemServicoManual: false,
      painelFilter: false,
      painelKanban: false,
      dialogCancelarOs: false,
    };
  },

  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    POrdensServicosVisualizar() {
      return this.getAccess("OrdensServicos", "visualizar");
    },
    POrdensServicosAdicionar() {
      return this.getAccess("OrdensServicos", "adicionar");
    },

    headers() {
      return [
        {
          text: "id",
          value: "id",
        },
        {
          text: this.$tc("global.cliente"),
          value: "cliente_nome",
        },
        {
          text: this.$tc("global.previsaoInicio"),
          value: "previsao_inicio",
        },
        {
          text: this.$tc("global.previsaoFim"),
          value: "previsao_fim",
        },
        {
          text: this.$tc("global.moeda"),
          value: "moeda_sigla",
        },
        {
          text: this.$tc("global.valor"),
          value: "valor",
        },
        {
          text: this.$tc("global.desconto"),
          value: "desconto",
        },
        {
          text: this.$tc("global.total"),
          value: "total",
        },
        {
          text: this.$tc("global.status"),
          value: "status",
        },
        {
          text: "",
          value: "actions",
        },
      ];
    },

    ...mapState("Usuario", {
      empresa: (state) => state.empresa,
    }),

    status_lista() {
      return [
        {
          text: this.$tc("global.cancelado"),
          value: -1,
          color: "red darken-1",
        },
        {
          text: this.$tc("global.pendentes"),
          value: 1,
          color: "yellow lighten-1",
        },
        {
          text: this.$tc("global.emPreparação"),
          value: 2,
          color: "lime darken-2",
        },
        {
          text:
            this.$tc("global.aguardando") + " " + this.$tc("global.instalacao"),
          value: 3,
          color: "light-blue darken-3",
        },
        {
          text: "Em " + this.$tc("global.instalacao"),
          value: 4,
          color: "orange lighten-1",
        },
        {
          text: this.$tc("global.finalizado"),
          value: 5,
          color: "green lighten-1",
        },
      ];
    },

    url() {
      let queryString = "";
      if (this.statusFilter.length) {
        queryString += `&status_filter=${this.statusFilter.join()}`;
      }
      if (this.dataInicio) {
        queryString += `&dataTo=${this.dataInicio}`;
      }
      if (this.dataFim) {
        queryString += `&dataFrom=${this.dataFim}`;
      }
      if (this.selectedFornecedor) {
        queryString += `&fornecedor=${this.selectedFornecedor.id}`;
      }

      return `?${queryString}`;
    },
  },

  watch: {},

  methods: {
    openClosePainelFilter() {
      if (this.painelFilter) {
        this.closePainelFilter();
      } else {
        this.painelFilter = !this.painelFilter;
      }
    },
    async closePainelFilter() {
      this.painelFilter = false;
      this.statusFilter = [1, 2, 3, 4, 5];
      this.dataInicio = null;
      this.dataFim = null;
      this.selectedFornecedor = null;
      await this.getOrdemServicos();
    },
    addOrdemServicoManual() {
      this.editar = false;
      this.dialogOrdemServicoManual = true;
    },
    goToOrdemServico(item) {
      this.ordem_servico_id = item.id;
      this.editar = true;
      if (item.origem == "MANUAL") {
        this.dialogOrdemServicoManual = true;
      } else {
        this.dialogOrdemServico = true;
      }
    },

    atualizarFornecedor(event) {
      this.selectedFornecedor = event;
    },

    openCancelamentoOs(item) {
      console.log("dados para cancelar", item);
      this.dadosParaCancelar = { ...item };
      this.dialogCancelarOs = true;
    },

    getOrdemServicos() {
      this.loading = true;

      return fetchOrdensServico(this.url)
        .then((response) => {
          this.ordem_servicos = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    async gerarCheckList(item) {
      try {
        this.loadingPdf = true;

        await pdfChecklist()
          .then((module) => {
            let generate = module.generatePdf;
            if (item.origem == "MANUAL") {
              generate(this.empresa, item.id, "manual").then((response) => {
                this.pdfData = response;
                this.pdfDialog = true;
              });
            } else {
              generate(this.empresa, item.id).then((response) => {
                this.pdfData = response;
                this.pdfDialog = true;
              });
            }
          })
          .finally(() => {
            this.loadingPdf = false;
          });
      } catch (error) {
        this.loadingPdf = false;
      }
    },

    async gerarTermo(item) {
      try {
        this.loadingPdf = true;

        if (item.origem == "MANUAL") {
          await pdfTermoManual()
            .then((module) => {
              let generate = module.generatePdf;
              generate(item.id).then((response) => {
                this.pdfData = response;
                this.pdfDialog = true;
              });
            })
            .finally(() => {
              this.loadingPdf = false;
            });
        } else {
          await pdfTermo()
            .then((module) => {
              let generate = module.generatePdf;
              generate(item.id, item.origem_id).then((response) => {
                this.pdfData = response;
                this.pdfDialog = true;
              });
            })
            .finally(() => {
              this.loadingPdf = false;
            });
        }
      } catch (error) {
        this.loadingPdf = false;
      }
    },
    async gerarpdf(item) {
      try {
        this.loadingPdf = true;

        if (item.origem == "MANUAL") {
          await listaOsManual()
            .then((module) => {
              let generate = module.generatePdf;
              generate(this.empresa, item.id).then((response) => {
                this.pdfData = response;
                this.pdfDialog = true;
              });
            })
            .finally(() => {
              this.loadingPdf = false;
            });
        } else {
          await pdfGenerator()
            .then((module) => {
              let generate = module.generatePdf;
              generate(this.empresa, item.id).then((response) => {
                this.pdfData = response;
                this.pdfDialog = true;
              });
            })
            .finally(() => {
              this.loadingPdf = false;
            });
        }
      } catch (error) {
        this.loadingPdf = false;
      }
    },
    podeDeletar(item) {
      return (
        item.status >= 5 &&
        item.data_pago_caixa == format(new Date(), "yyyy-MM-dd")
      );
    },
  },

  mounted() {
    this.getOrdemServicos();
    if (!this.$vuetify.breakpoint.mdAndUp) {
      this.painelKanban = true;
    }
  },

  beforeUpdate() {
    if (!this.$vuetify.breakpoint.mdAndUp) {
      this.painelKanban = true;
    }
  },
};
</script>

<style></style>
